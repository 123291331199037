import { useEffect } from "react";

import {
  LockScreenOrientationVariant,
  useDeprecatedScreenOrientation,
} from "./useScreenOrientation";

/**
 * Locks the screen orientation to portrait primary on mount.
 *
 * Note: This should be removed as soon as the vertical signature flow
 * is released and cleaned up. This should be an app-wide setting, and
 * we should remove the screen-orientation plugin.
 * https://linear.app/clipboardhealth/issue/TX-1777
 */
export function LockScreenOrientation() {
  const { lockOrientation } = useDeprecatedScreenOrientation();

  // Lock the screen orientation on mount
  useEffect(() => {
    void lockOrientation(LockScreenOrientationVariant.PORTRAIT_PRIMARY);
  }, [lockOrientation]);

  return null;
}
