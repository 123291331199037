import { ScreenOrientation, type ScreenOrientationResult } from "@capacitor/screen-orientation";
import { APP_V2_APP_EVENTS, isCapacitorPlatform } from "@src/appV2/lib";
import { useCallback, useEffect, useState } from "react";

import { logError } from "../lib/analytics/log";

// Must be a subset of OrientationLockType from @capacitor/screen-orientation
export enum LockScreenOrientationVariant {
  NATURAL = "natural",
  PORTRAIT_PRIMARY = "portrait-primary",
  LANDSCAPE_PRIMARY = "landscape-primary",
  PORTRAIT_SECONDARY = "portrait-secondary",
  LANDSCAPE_SECONDARY = "landscape-secondary",
}

export interface UseScreenOrientation {
  orientation: OrientationType | undefined;
  lockOrientation: (orientation: LockScreenOrientationVariant) => Promise<void>;
  unlockOrientation: () => Promise<void>;
}

/**
 * @deprecated
 * Screen locking behavior should be avoided across the app. This hook
 * is on path to be removed, and an alternative solution will be implemented
 * that does not depend on locking the screen orientation.
 *
 * TODO: Add test coverage for this hook
 */
export function useDeprecatedScreenOrientation(): UseScreenOrientation {
  const [orientation, setOrientation] = useState<OrientationType>();
  const isPlatformMobile = isCapacitorPlatform();

  useEffect(() => {
    if (!isPlatformMobile) {
      return () => null;
    }

    (async () => {
      const currentOrientation = await ScreenOrientation.orientation();
      setOrientation(currentOrientation.type);
    })();

    const orientationChangeListenerHandle = ScreenOrientation.addListener(
      "screenOrientationChange",
      (orientation: ScreenOrientationResult) => {
        setOrientation(orientation.type);
      }
    );

    return () => {
      void orientationChangeListenerHandle.then((listenerHandle) => {
        void listenerHandle.remove();
      });
    };
  }, [isPlatformMobile]);

  const lockOrientation = useCallback(
    async (orientation: LockScreenOrientationVariant) => {
      if (!isPlatformMobile) {
        return;
      }

      // unlock the orientation first in case it was previously locked
      await ScreenOrientation.unlock().catch((error: unknown) => {
        /* locks not supported, no action */
        logError(APP_V2_APP_EVENTS.SCREEN_ORIENTATION_LOCK_FAILED, {
          error,
          metadata: { operation: "unlock" },
        });
      });

      // lock the orientation to the desired orientation
      await ScreenOrientation.lock({ orientation }).catch((error: unknown) => {
        /* locks not supported, no action */
        logError(APP_V2_APP_EVENTS.SCREEN_ORIENTATION_LOCK_FAILED, {
          error,
          metadata: { operation: "lock" },
        });
      });
    },
    [isPlatformMobile]
  );

  const unlockOrientation = useCallback(async () => {
    if (!isPlatformMobile) {
      return;
    }

    // unlock the orientation first
    await ScreenOrientation.unlock().catch((error: unknown) => {
      /* locks not supported, no action */
      logError(APP_V2_APP_EVENTS.SCREEN_ORIENTATION_LOCK_FAILED, {
        error,
        metadata: { operation: "unlock" },
      });
    });

    // By default, we want to forcefully lock the screen orientation to portait
    await ScreenOrientation.lock({
      orientation: LockScreenOrientationVariant.PORTRAIT_PRIMARY,
    }).catch((error: unknown) => {
      /* locks not supported, no action */
      logError(APP_V2_APP_EVENTS.SCREEN_ORIENTATION_LOCK_FAILED, {
        error,
        metadata: { operation: "lock" },
      });
    });
  }, [isPlatformMobile]);

  return {
    orientation,
    lockOrientation,
    unlockOrientation,
  };
}
